/* RESET */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

/* ReUseables */
:root {
  --darkShade: #333333;
  --yellowShade: #E8CE6A;
}

.App {
  width: 100%;
  height: 100vh;
  background-color: var(--darkShade);
}

.header {
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: var(--darkShade);
}


::-webkit-scrollbar-thumb {
  height: 10px;
  background-color: var(--yellowShade);
  border-radius: 200px;
}

/* Roll */
.rolldivLeft {
  position: absolute;
  top: 50px;
  left: -220px;
  overflow: hidden;
}

.rollLeft {
  background: url('./Assets/pageSpinner.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 350.784px;
  width: 350.784px;
  height: 350.784px;
  flex-shrink: 0;
  position: relative;
  animation: spinleft 10s infinite linear
}

.rolldivRight {
  position: absolute;
  top: 400px;
  right: -180px;
  overflow: hidden;
}

.rolldivRightH {
  position: absolute;
  top: 650px;
  right: -180px;
  overflow: hidden;
}

.rollRightH {
  background: url('./Assets/pageSpinner.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 350.784px;
  height: 350.784px;
  flex-shrink: 0;
  position: relative;
  animation: spinRight 10s infinite linear;
}

.rollRight {
  background: url('./Assets/pageSpinner.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 350.784px;
  height: 350.784px;
  flex-shrink: 0;
  position: relative;
  animation: spinRight 10s infinite linear;
}

@keyframes spinleft {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinRight {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}


.protocolBG {
  background-image: url('./Assets/GataDarkBG.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.spin-btn {
  border-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='100' height='100' viewBox='0 0 100 100' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cstyle%3Epath%7Banimation:stroke 5s infinite linear%3B%7D%40keyframes stroke%7Bto%7Bstroke-dashoffset:776%3B%7D%7D%3C/style%3E%3ClinearGradient id='g' x1='0%25' y1='0%25' x2='0%25' y2='100%25'%3E%3Cstop offset='0%25' stop-color='%23E8CE6A' /%3E%3Cstop offset='25%25' stop-color='%23E8CE6A' /%3E%3Cstop offset='50%25' stop-color='%23E8CE6A' /%3E%3Cstop offset='100%25' stop-color='%23E8CE6A' /%3E%3C/linearGradient%3E %3Cpath d='M1.5 1.5 l97 0l0 97l-97 0 l0 -97' stroke-linecap='square' stroke='url(%23g)' stroke-width='3' stroke-dasharray='388'/%3E %3C/svg%3E") 1;
}


.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }
}

.spin {
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes slideInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* .hidden {
  opacity: 0;
} */

.slide-in-left {
  animation: slideInLeft 1s ease-out forwards;
}

.slide-in-right {
  animation: slideInRight 1s ease-out forwards;
}

.input {
  background: rgba(115, 115, 115, 0.24);
  backdrop-filter: blur(10.5px);
}

@keyframes spinY {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}


.transition-max-height {
  transition: max-height 0.3s ease-in-out;
}

.spin-image {
  animation: spinY 10s linear infinite; 
  cursor: grab;
  transition: transform 0.1s ease-out;
}

.loader {
  width: 100px;
  aspect-ratio: 4;
  --c: #E8CE6A 90%, #0000;
  background-color: #333333; /* Background color for loader container */
  background: 
    radial-gradient(circle closest-side at left 10px top 50%, var(--c)),
    radial-gradient(circle closest-side                    , var(--c)),
    radial-gradient(circle closest-side at right 10px top 50%, var(--c));
  background-size: 100% 100%;
  background-repeat: no-repeat;
  animation: l4 1s infinite alternate;
}



@keyframes l4 {
  to {
    width: 25px;
    aspect-ratio: 1;
  }
}

@media (max-width: 1024px) {
  .rolldivRight {
    top: 450px;
    right: -200px;
  }

  .rolldivRightH {
    top: 350px;
    right: -200px;
  }
}


@media (max-width: 414px) {
  #gatamarket {
    padding-bottom: 120px;
  }

  #gatanow {
    padding-top: 50px;
  }

  #gatatokenomics {
    padding: 70px 0;
  }
}

@media (max-width: 375px) {
  #gatamarket #gatamarketimg {
    margin: -25px 0;
  }

  #gatatokenomics {
    padding: 4px 0;
  }
}

@media only screen and (width: 820px) {
  #gatamarket {
    padding-bottom: 220px;
  }

  #gatanow {
    padding-top: 50px;
  }

  #gatatokenomics {
    padding-bottom: 240px;
  }
}

@media only screen and (width: 430px) {
  #gatamarket {
    padding-bottom: 220px;
    padding-top: 100px;
  }

  #gatanow {
    margin-top: -60px;
  }

  #gatatokenomics {
    padding-bottom: 240px;
  }
}

@media only screen and (width: 344px) {
  #gatatokenomics {
    padding-bottom: 100px;
  }
}

@media only screen and (width: 360px) {
  #gatanow {
    margin-top: -80px;
  }
}

@media only screen and (width: 540px) {
  #gatamarket {
    padding-bottom: 60px;
  }

  #gatatokenomics {
    padding-bottom: 50px;
  }
}


@media only screen and (width: 912px) {
  #theprotocol {
    padding: 100px 0;
  }
  #gatamarket {
    padding-bottom: 300px;
    padding-top: 200px;
  }

  #gatanow {
    padding: 100px 0;
  }

  #gatatokenomics {
    padding-top: 100px;
    padding-bottom: 420px;
  }
}


@media only screen and (width: 1024px) {
  #heroAngle img {
    width: 500px;
    height: 500px;
    margin: 150px 0;
  }

  #footer {
    padding: 20px 20px;
  }
}